import {
  Button,
  ButtonProps,
  Link as MaterialLink,
  LinkProps as MaterialProps,
} from '@mui/material'
import { forwardRef } from 'react'
import { Link as RRDLink } from 'react-router-dom'

export type LinkProps = MaterialProps & {
  to: string
}
export const Link = (props: LinkProps) => (
  <MaterialLink component={RRDLink} {...props} />
)

export type ButtonLinkProps = ButtonProps & {
  to: string
  linkProps?: MaterialProps
}
export const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  function ButtonLink(props, ref) {
    const { linkProps, to, children, ...rest } = props
    if (props.disabled) return <Button {...rest}>{children}</Button>
    return (
      <Link {...linkProps} to={to} underline="none">
        <Button {...rest} ref={ref}>
          {children}
        </Button>
      </Link>
    )
  }
)
