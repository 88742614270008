import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material'
import { When } from 'react-if'
import { useLocation } from 'react-router-dom'

import { Icon, MuiIconNames } from '@/components'

export type DrawerItemProps = {
  open: boolean
  tooltipOpen: string | null
  title: string
  onClose: () => void
  onOpen: () => void
  path: string
  enabled?: boolean
  iconName?: MuiIconNames
  onClick: () => void
  sx?: SxProps<Theme>
}
export const DrawerItem = (props: DrawerItemProps) => {
  const {
    tooltipOpen,
    title,
    onClose,
    onClick,
    onOpen,
    path,
    iconName,
    open,
    enabled,
  } = props
  const location = useLocation()

  const isSelected = () => {
    const { pathname, search } = location
    if (pathname === path || `${pathname}${search}` === path) return true
    return false
  }

  return (
    <Tooltip
      arrow
      open={!open && tooltipOpen === title}
      placement="right"
      title={title}
      onClose={onClose}
      onOpen={onOpen}
    >
      <ListItemButton selected={isSelected()} sx={{ p: 0 }}>
        <ListItemButton
          disabled={!enabled}
          sx={{
            justifyContent: open ? 'initial' : 'center',
            minHeight: 48,
            px: 2.5,
            ...props.sx,
          }}
          onClick={onClick}
        >
          <ListItemIcon
            sx={{
              justifyContent: 'center',
              minWidth: 0,
              mr: open ? 3 : 'auto',
            }}
          >
            <When condition={!!iconName}>
              <Icon name={iconName as MuiIconNames} />
            </When>
          </ListItemIcon>
          <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItemButton>
    </Tooltip>
  )
}
