import { useMemo } from 'react'

import { ITab } from '@/components'

import useUrlParams from '../useUrlParams'

export const useTabs = (
  tabs: ITab['name'][],
  defaultValue: ITab['name'],
  tabName: string = 'tab'
) => {
  const [{ tab = defaultValue ?? '' }, setUrlParams] = useUrlParams()
  const selectTab = (_: any, newValue: keyof typeof tabs) => {
    setUrlParams({ [tabName]: tabs?.[newValue] ?? defaultValue })
  }

  const selectedTab = useMemo(() => {
    const tabIndex = tabs.indexOf(tab)
    return tabIndex !== -1 ? tabIndex : tabs.indexOf(defaultValue ?? '')
  }, [defaultValue, tab, tabs])

  return [selectedTab as number, selectTab] as const
}
