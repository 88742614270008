import { Tab, Tabs } from '@mui/material'
import { ReactNode } from 'react'

import { useUrlParams } from '@/hooks'
import { useTabs } from '@/hooks/useTabs'

export interface ITab {
  name: string
  component: ReactNode
}
export interface EditTabsPatternProps<T extends ITab> {
  tabs: T[]
  defaultTab: T['name']
  showTabs: boolean
  tabTitle?: string
}
/**
 * This pattern will have all of the tabs for any edit page. The tabs are going to be rendered based if "showTabs" is true or false.
 * If "showTabs" is false, then the default tab will be rendered.
 * If "showTabs" is true, then all of the tabs will be rendered.
 *
 * @example
 * <EditTabsPattern
 *   tabs={[
 *     { name: "Tab 1", component: <div>Tab 1</div> },
 *     { name: "Tab 2", component: <div>Tab 2</div> },
 *   ]}
 *   defaultTab="Tab 1"
 *   showTabs={false}
 * />
 *
 *
 * @param {Object} props - The props of the component.
 * @param {ITab[]} props.tabs - The list of tabs to render.
 * @param {string} props.defaultTab - The default tab to render.
 * @param {boolean} props.showTabs - Whether to show the tabs or not.
 */
export function EditTabsPattern<T extends ITab>(
  props: EditTabsPatternProps<T>
) {
  const { tabs, defaultTab, showTabs = false } = props
  const [selectedTab, setTab] = useTabs(
    tabs.map((t) => t.name),
    defaultTab
  )
  const [{ tab }] = useUrlParams()

  const defaultTabComponent = tabs.find((t) => t.name === defaultTab)?.component
  if (!showTabs) return <>{defaultTabComponent}</>

  return (
    <>
      <Tabs
        id="tabs"
        sx={{ mb: 2, overflowX: 'auto' }}
        value={selectedTab}
        onChange={setTab}
      >
        {tabs.map(({ name: tab }, index) => (
          <Tab
            key={tab}
            id={`tab-${tab}`.toLowerCase()}
            label={tab}
            value={index}
          />
        ))}
      </Tabs>
      {!!tab ? tabs?.[selectedTab]?.component : defaultTabComponent}
    </>
  )
}
